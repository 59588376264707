<style lang='scss' scoped>
    .VipUpdateLog {
        margin: 0 auto;
        position: relative;
        width: 965px;
        height: 700px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
    }

    .div_updatePwd li {
        position: relative;
        width: 100%;
        height: 46px;
        margin-top: 25px;
        text-align: left;
    }

    .span_color {
        display: inline-block;
        line-height: 46px;
        width: 110px;
        color: #999999;
        text-align: right;
        margin-right: 15px;
        font-size: 14px;
    }

    .span_tip_common {
        height: 46px;
        position: absolute;
        margin-left: 20px;
        font-size: 12px;
        width: 300px;
    }

    .span_tip_red {
        font-size: 14px;
        color: #ee2e2e;
        line-height: 46px;

    }

    .span_tip_grey {
        color: #999999;
    }

    .input {
        width: 302px;
        height: 46px;
        background-color: #ffffff;
        border: solid 1px #cfcfcf;
        padding-left: 25px;
        outline: none;
    }

    .div_updatePwd button {
        width: 90px;
        height: 30px;
        background-color: #ee2e2e;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
        margin-left: 124px;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 32px;
        margin-left: 28px;
    }

    .span_safe {
        width: 61px;
        height: 12px;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .div_safe {
        height: 21px;
        margin-left: 124px;
        margin-top: 22px;
    }

    .span_safe_common {
        display: inline-block;
        width: 36px;
        height: 6px;
        margin-bottom: 2px;
    }

    .span_safe_0 {
        background-color: #dfdfdf;
    }

    .span_safe_1 {
        background-color: #f14f4f;
    }

    .span_safe_2 {
        background-color: #00a0e9;
    }

    .span_safe_3 {
        background-color: lightgreen;
    }

    /deep/.el-button--primary:hover{
      background-color: #fff;
      border: 1px solid #ee2e2e;
      color: #ee2e2e;
    }
    /deep/.el-button--primary {
      background-color: #fff;
      border: 1px solid #ee2e2e;
      color: #ee2e2e;
    }
</style>
<template>
  <div class="VipUpdateLog" style="height:864px">
    <p class="myclass-title">
      <span class="title-text">会员升级记录
        <span v-if="resultVipLog.result.count>0">({{ resultVipLog.result.count }})</span>
      </span>
      <span class="title-border"></span>
    </p>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        label="日期"
        align="center"
        width="154"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_create_time }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="升级方式"
        align="center"
        width="154"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_upgradeMode }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="会员级别"
        align="center"
        width="154"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_vipLevel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="付款金额"
        align="center"
        width="154"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_money }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="有效期"
        width="154"
        align="center"
      >
        <template slot-scope="scope">
          <span style="margin-left: 0">{{ scope.row.table_product_end_time }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="发票"
        align="center"
      >
        <template slot-scope="scope">
          <el-button v-if="scope.row.is_invoice === 1 && scope.row.invoice_status === 0" type="primary" @click="goToMakeInvoice(scope.row)">去开发票</el-button>
          <el-button v-else-if="scope.row.is_invoice === 1 && (scope.row.invoice_status === 1||scope.row.invoice_status === 2)" type="primary" @click="goToMakeInvoice(scope.row)">查看发票</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      style="margin-top: 16px"
      layout="prev, pager, next"
      :page-size="params.limit"
      :total="resultVipLog.result.count"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getVipUpdateLog } from '../../../api/mine'
import { getInvoiceStatus } from '@/api/center'

export default {
  data() {
    return {
      params: {
        page: '',
        limit: 10
      },
      resultData: [{
        order_id: 23,
        money: 100,
        order_type: 1,
        product_end_time: '2021-03-21 23:59:59',
        create_time: '2021-03-23 16:20:54'

      }, {
        order_id: 23,
        money: 0,
        order_type: 2,
        product_end_time: '2021-03-22 00:00:01',
        create_time: '2021-03-22 17:22:43'
      }],
      resultVipLog: {
        msg: '',
        result: {
          list: [{
            order_id: 23, // 类型：Number  必有字段  备注：无
            money: 0, // 类型：Number  必有字段  备注：无
            order_type: 1, // 类型：Number  必有字段  备注：无
            product_end_time: '2021-03-27 23:59:59', // 类型：String  必有字段  备注：无
            create_time: '2021-03-23 16:20:54' // 类型：String  必有字段  备注：无
          }],
          count: 0
        }
      },
      tableData: []
    }
  },
  created() {
    this.params.page = 1
    this.getVipLogInfo(this.params)
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val, 'val')
      this.params.page = val
      this.getVipLogInfo(this.params)
    },
    // 获取vip日志信息
    getVipLogInfo(params) {
      getVipUpdateLog(params).then((res) => {
        this.resultVipLog.msg = res.msg
        this.resultVipLog.result = res.result
        const result = this.resultVipLog.result.list
        this.tableData = []
        for (let i = 0; i < res.result.list.length; i++) {
          const obj = {
            table_create_time: '',
            table_product_end_time: '',
            table_upgradeMode: '',
            table_vipLevel: '',
            table_money: '',
            is_invoice: 0,
            invoice_status: 0,
            invoice_url: '',
            order_sn: '',
            order_id: ''
          }
          obj.table_create_time = result[i].create_time.substring(0, 10)
          obj.table_product_end_time = result[i].product_end_time.substring(0, 10)
          // 1社区会员 2VIP会员
          // if (parseInt(result[i].order_type) === 1) {
          //   obj.table_vipLevel = '社区会员'
          // } else if (parseInt(result[i].order_type) === 2) {
          //   obj.table_vipLevel = 'VIP会员'
          // } else {
          //   obj.table_vipLevel = '会员'
          // }
          obj.table_vipLevel = result[i].name
          obj.table_upgradeMode = result[i].upgradeMode
          // if (parseInt(result[i].money) > 0) {
          //   obj.table_upgradeMode = '购买VIP'
          // } else {
          //   obj.table_upgradeMode = '后台设置'
          // }
          obj.table_money = result[i].money
          var is_invoice = result[i].is_invoice ? result[i].is_invoice : 0
          var invoice_status = result[i].invoice_status ? result[i].invoice_status : 0
          obj.is_invoice = Number(is_invoice)
          obj.invoice_status = Number(invoice_status)
          obj.invoice_url = result[i].invoice_url
          obj.order_sn = result[i].order_sn
          obj.order_id = result[i].order_id
          this.tableData.push(obj)
        }
      })
    },
    // 修改table header的背景色
    tableHeaderColor() {
      return 'color: #020202;font-weight:600;font-size:14px;text-align:center'
    },
    goToMakeInvoice(item) {
      var params = {
        order_sn: item.order_sn
      }
      getInvoiceStatus(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          if (res.result.edit === 1) {
            // 可以开发票
            this.pushInvoice(item)
          } else if (res.result.edit === 0) {
            if ((res.result.invoice_type === 1 || res.result.invoice_type === 4 || res.result.invoice_type === 5) && res.result.invoice_url.length > 0) {
              window.open(res.result.invoice_url)
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    pushInvoice(item) {
      this.$router.push({
        path: '/Invoice',
        query: {
          order_id: item.order_id,
          order_sn: item.order_sn
        }
      })
    }
  }
}
</script>

